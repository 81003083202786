import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
  {
    path: '/listTemplate',
    name: 'listTemplate',
    component: () => import('../views/listTemplate.vue'),
    meta: {
      title: '视频会员'
    }
  },
  {
    path: '/details',
    name: 'details',
    component: () => import('../views/details.vue'),
    meta: {
      title: '商品详情'
    }
  },
  {
    path: '/orderTemplate',
    name: 'orderTemplate',
    component: () => import('../views/orderTemplate.vue'),
    meta: {
      title: '我的订单'
    }
  },
  {
    path: '/goodslist',
    name: 'goodlsist',
    component: () => import('../views/moudels/list/goodslist.vue'),
    meta: {
      title: '视频会员'
    }
  },
  {
    path: '/checkOrder',
    name: 'checkOrder',
    component: () => import('../views/moudels/order/checkOrder.vue'),
    meta: {
      title: '会员充值订单'
    }
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/moudels/order/order.vue'),
    meta: {
      title: '会员充值订单'
    }
  },
  {
    path: '/order_w',
    name: 'order_w',
    component: () => import('../views/moudels/order/order_w.vue'),
    meta: {
      title: '会员充值订单'
    }
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import('../views/recharge.vue'),
    meta: {
      title: '会员充值'
    }
  },
  {
    path: '/signergys',
    name: 'signergys',
    component: () => import('../views/shangjie/signergys.vue'),
    meta: {
      title: '恭喜获得'
    }
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import('../views/service.vue'),
    meta: {
      title: '客服'
    }
  }
  ,
  {
    path: '/h5_details',
    name: 'h5_details',
    component: () => import('../views/moudels/pay/h5_details.vue'),
    meta: {
      title: '商品详情'
    }
  },
  {
    path: '/h5_recharge',
    name: 'h5_recharge',
    component: () => import('../views/moudels/recharge/h5_recharge.vue'),
    meta: {
      title: '商品详情'
    }
  },
  {
    path: '/weChat_recharge',
    name: 'weChat_recharge',
    component: () => import('../views/moudels/recharge/weChat_recharge.vue'),
    meta: {
      title: '商品详情'
    }
  },
  {
    path: '/wechatPay',
    name: 'wechatPay',
    component: () => import('../views/moudels/pay/wechatPay.vue'),
    meta: {
      title: '商品详情'
    }
  },
  {
    path: '/queryorder',
    name: 'queryorder',
    component: () => import('../views/queryorder.vue'),
    meta: {
      title: '充值订单查询'
    }
  },
  {
    path: '/wx/coupon/default',
    name: 'Default',
    component: () => import('../views/wechat/weChatFunds.vue'),
    meta: {
      title: '微信立减金'
    }
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('../views/wechat/success.vue'),
    meta: {
      title: '微信立减金'
    }
  },
]
const router = new VueRouter({
  // mode: 'history',
  base: process.env.VUE_APP_PATH,
  routes
})
export default router